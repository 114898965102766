<template>
    <transition v-if="visible" name="slide-fade" @after-enter="$emit('afterEnter')">
        <div class="drawer-wrapper" :style="{'z-index': zIndex-1 }">
            <div class="drawer-view" :style="{ 'background-color': backgroundColor, 'z-index': zIndex }" >
                <el-card v-loading="loading" class="card-container" :style="{ 'width': width,'height':'100%'}" :body-style="{'height':'100%',position:'relative',display:'flex',flexDirection:'column'}">
                    <div class="section-header">
                        <div v-if="title || $route.name || $route.meta.name" class="header-title">{{title || $route.name || $route.meta.name}}</div>
                        <div class="header">
                            <slot name="header"/>
                            <img class="close" @click="close" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTggKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkE5RDA1QTM2RTE2RjExRThCNTA3RDU0NzZGMDlDNzcxIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkE5RDA1QTM3RTE2RjExRThCNTA3RDU0NzZGMDlDNzcxIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QTlEMDVBMzRFMTZGMTFFOEI1MDdENTQ3NkYwOUM3NzEiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QTlEMDVBMzVFMTZGMTFFOEI1MDdENTQ3NkYwOUM3NzEiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6ZdSD+AAAA8UlEQVR42qzWMQrCMBgF4PRZj+DgDRx08gQOIk7eVnTR0cGKk6cQN3FUX6CFEtr0/5M/8Aolbb+S8kKLqqpuzrkfs2WeznZMmSPzBg8Fs2ROzMQY8c9cMF8PbZgHMzfEGmTG3Jkd6uVaGWIhsmZeqCetsE7ET6B1US7Wi4RQDhZFuqAUbBDpgzSYCIlBEkyMDEExTIX4UQo+dIOdW1ipQaRQF+Y0iGTpwpcatc7HmvuRWEZ1qZFYRnWpkdh49Q6CjMarMORsKxoMOduKBkMmIsZggIgwGCGDGAyRKOahgyHSh+099GEuhkiIXf0P5F+AAQDYHIszFmtpKgAAAABJRU5ErkJggg==" />
                        </div>
                    </div>
                    <div class="section-content" ref="content">
                        <slot />
                    </div>
                    <div v-if="$slots['footer']" class="section-footer">
                        <slot name="footer"/>
                    </div>
                </el-card>
            </div>
        </div>
    </transition>
</template>
<script>
export default {
    name: "pageDrawer",
    props: {
        title  : {type: String,default: ''},//展示内容的宽
        width  : {type: String,default: '924px'},//展示内容的宽
        zIndex : {type: String,default: '1000'},//层级关系
        loading: {type: Boolean,default: false},//loading
        backgroundColor: {type: String,default: 'rgba(0, 0, 0, 0)'},// 更改背景颜色颜色
    },
    data(){
        return {
            visible:false,
        }
    },
    methods: {
        open(){
            this.visible = true,this.$emit("onopen");
        },
        close(){
            this.visible = false,this.$emit("onclose");
        }
    },
}
</script>
<style lang="scss" scoped>

    .slide-fade-enter-active,.slide-fade-leave-active {
        will-change: transform;
        transition: all 0.35s ease;
    }
    .slide-fade-enter,.slide-fade-leave-to {
        transform: translateX(100%);
    }

    .clearfix:before,.clearfix:after {
        display: table;
        content: "";
    }
    .clearfix .close{
        display: block;
        width: 40px;
        height: 40px;
        margin-right: -10px;
        padding: 10px;
        cursor: pointer;
    }
    .clearfix:after {
        clear: both
    }

    .drawer-wrapper{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .drawer-view {
        position: fixed;
        width: 926px;
        top: 60px;
        bottom: 0;
        right: 0;
        
        .card-container{
            position: relative;
            right: 0;
            height: 100%;
            .section-header{
                display: flex;
                .header{
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    flex:1;
                    .close{
                        display: block;
                        margin-right: -10px;
                        width: 35px;
                        height: 35px;
                        padding: 10px;
                        cursor: pointer;
                    }
                }
                .header-title{
                    display: flex;
                    align-items: center;
                    font-size: 17px;
                }
            }
            .section-content{
                position  : relative;
                overflow-x: hidden;
                overflow-y: auto;
                -ms-flex: 1;
                flex: 1;
            }
        }
    }
</style>